/* eslint-disable vue/no-unused-components */
<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar">
    <div class="loader"></div>
      </div>-->
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-8 column">
              <div class="heading-profile">
                <h3>Transactions / Accounts</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right"></div>
                    <div>
                      <div class="d-flex">
                        <div>
                          <h5>
                            List of Transactions / Accounts
                            <span class="tableTotal">(Total : {{ totalCount }})</span>
                          </h5>
                        </div>
                        <div class=" ml-3 ">
                          <b-button variant="success" @click="generateExcelForAllReport(page)"
                            >Download</b-button
                          >
                        </div>
                        <div class="ml-auto">
                          <form class="search-form mr-form">
                            <input
                              type="text "
                              class="form-control"
                              v-model="searchText"
                              @input="viewReportList(1)"
                              placeholder="Search Here..."
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive text-nowrap">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>

                          <th>Name</th>
                          <th>Partner</th>
                          <th>Date of Sign-up</th>
                          <th>Particulars</th>
                          <th>Start &amp; End Date Of Package/Ads</th>
                          <!-- <th>Party Name</th> -->
                          <th>Amount</th>
                          <th>GST Amount</th>

                          <th colspan="2">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(report, index) in reports" v-bind:key="report._id">
                          <td>{{ index + 1 + (page - 1) * 10 }}</td>
                          <td>
                            <div>{{ report.userName }}</div>
                          </td>
                          <td>
                            <div>{{ report.partyName }}</div>
                          </td>
                          <td>
                            <div>{{ report.userSignupDate }}</div>
                          </td>
                          <td>
                            <div>{{ report.particulars }}</div>
                          </td>
                          <td>
                            <div>{{ report.startEndDate }}</div>
                          </td>
                          <td>
                            <div>{{ report.amountRecd }}</div>
                          </td>
                          <td>
                            <div>{{ report.invoiceValue }}</div>
                          </td>
                          <!-- v-repeat -->

                          <td>
                            <div class="d-flex">
                              <div class="ml-2 cursor-pointer">
                                <router-link
                                  class="btn btn-primary"
                                  :to="{ name: 'viewReport' }"
                                  append
                                >
                                  <b-icon-eye></b-icon-eye>
                                </router-link>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <!-- end v-repeat -->
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                    v-if="reports.length === 0"
                  >
                    No Data Found
                  </div>
                  <div class="text-center" v-if="reports.length >= 1">
                    <b-pagination
                      class="mb-0 float-right"
                      v-model="page"
                      align="center"
                      :total-rows="totalCount"
                      :per-page="formData.limit"
                      @input="viewReportList(page)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import moment from "moment";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      currentPage: 1,
      reports: [],
      page: 1,
      searchText: "",
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: []
    };
  },
  created() {
    this.viewReportList(this.page);
  },
  methods: {
    viewReportList(pageNo) {
      this.reports = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      service.viewReports(this.formData, data => {
        if (data.status === 200) {
          this.reports = data.data.data;
         
          this.totalCount = data.data.TotalCount;
        } else {
        
        }
      });
    },
    generateExcelForAllReport(pageNo) {
      service.generateExcelForAllReport(this.formData, data => {
        service.downloadExcel(data, "Transaction_List");
      });
    }
  }
};
</script>

<style></style>
